import { React, Link, useNavigate } from "common";
import { LoanTabsListNav } from "../../components";
import RateQuote from "../../popups/quotepop";

const tabItemsList = [
    {
        id: "idpurchase",
        header: "Purchase New House",
        items: [
            "Budget & Financial Considerations",
            "Legal & Regulatory Aspects",
            "Neighborhood & Community",
            "Future Growth & Development",
        ],
        link: "/firsthome",
    },
    {
        id: "idrefinancing",
        header: "Refinancing Your Home",
        items: [
            "Down payment as low as 3.5%",
            "Include all costs in one loan",
            "Steady, affordable monthly payments",
            "Qualify for higher loan amounts",
        ],
        link: "/steps",
    },
    {
        id: "idcapital",
        header: "Capital Management",
        items: [
            "No money down up to $417,000",
            "No PMI (Private Mortgage Insurance)",
            "Loans can be closed in 7 to 10 days",
            "Qualify for higher loan amounts",
        ],
        link: "/loanprocess",
    },
    {
        id: "idhouse",
        header: "House Renovation",
        items: [
            "Receive money from the loan",
            "Include all costs in one loan",
            "Steady, affordable monthly payments",
            "Qualify for higher loan amounts",
        ],
        link: "/renovation",
    },
    {
        id: "idrefinance",
        header: "Refinance Advisor",
        items: [
            "No down payment required",
            "Competitive 30-year fixed interest rates",
            "Flexible credit guidelines",
            "No maximum purchase limit",
        ],
        link: "/lowerpayment",
    },
    {
        id: "idprequalify",
        header: "Pre-Qualify For Loan",
        items: [
            "No maximum purchase limit",
            "Flexible credit guidelines",
            "Steady, affordable monthly payments",
            "Qualify for higher loan amounts",
        ],
        link: "/thirty",
    },
];

const Component = () => {

    const [showRateQuote, setShowRateQuote] = React.useState(false);
    const [tabInfo, setTabInfo] = React.useState(null);
    const NavigateTo = useNavigate();

    React.useEffect(() => {
        setTabInfo(tabItemsList[0]);
    }, []);

    const OnTabClicked = (e) => {
        let iElm = tabItemsList.filter((x) => x.id === e);
        if (iElm && iElm.length > 0) {
            setTabInfo(iElm[0]);
        } else {
            setTabInfo(tabItemsList[0]);
        }
    };

    const OnLinkClicked = (e, link) => {
        e.preventDefault();
        NavigateTo(link);
    };

    const OnRateQuoteClicked = async (e, bOpen) => {
        e.preventDefault();
        setShowRateQuote(bOpen);
    };

    return (
        <>
            <div className="serviceContainer">
                <div className="widthfix_10px">
                    <div className="serv_blkHead">What We Offer</div>
                    <div className="serv_blkTxtDesc">Our Core Services</div>
                    <div className="servTxt">We are committed to ensuring that you have a smooth and stress-free mortgage experience from application to closing, with transparent communication and support every step of the way.</div>

                    <ul className="loPointsDescBlk">
                        <li className="loPointsDescBlk">
                            <LoanTabsListNav
                                type="purchase"
                                home={true}
                                onTabClicked={OnTabClicked}
                            />
                        </li>
                        <li className="loPointsDescBlk">
                            <ul className="loDescBlk">
                                <li className="loDescBlk">
                                    <div className="loContentBox">
                                        <div className="loDescHead">{tabInfo?.header}</div>
                                        <ul className="loDescPoints">
                                            {tabInfo?.items.map((x, index) => (
                                                <li className="loDescPoints" key={index}>
                                                    {x}
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="loButtonLabel">
                                            <Link
                                                to={"#"}
                                                onClick={(e) => OnLinkClicked(e, tabInfo?.link)}
                                            >
                                                <li className="loButtonLabel">
                                                    <input
                                                        type="button"
                                                        value="Learn More"
                                                        className="loButton"
                                                    />
                                                </li>
                                            </Link>
                                            <li className="loGetQuote" style={{ display: "none" }}>
                                                <Link
                                                    to="#"
                                                    className="getQuotelink"
                                                    onClick={(e) => OnRateQuoteClicked(e, true)}
                                                >
                                                    Get Pre-Qualified
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="loDescBlk"></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {showRateQuote && <RateQuote onClose={(e) => OnRateQuoteClicked(e, false)} />}
        </>
    )
}



export default Component;