import { React, Link } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_career">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Job Openings</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Career Opportunity</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">Help us build the future of Mortgage Industry.</div>
                                    <div className="pgTxtNormal">At Siddhi Financials we are on a mission to build world class ecosystem for Mortgage industry. We are looking for talented friendly people to join us in this journey.</div>
                                    {/* <div className="pgTxtBold">Open Positions:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">Full-stack developer with node.js, ReactJS, React Native, Postgres  & MongoDB  experience on AWS platform.<br /><b>Experience :</b> 2 - 6 years</li>

                                        <li className="bp_circleOutline">.NET Developer with experience in  ASP.NET framework, C# .NET, HTML5/CSS3, APIs (REST, RPC) SQL Server and design/architectural patterns.<br /><b>Experience :</b> 2 - 6 years</li>
                                    </ul> */}

                                    <div className="pgTxtNormal">Email us your resume to <Link to="mailto:info@siddfi.com">info@siddfi.com</Link>, if you find yourself a fit.</div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
