const Component = () => {
    return (
        <ul className="benefitContainer">
            <div className="widthfix_10px">
                <li className="benefits">
                    <div className="benefitImage"></div>
                </li>
                <li className="benefits">
                    <div className="benefit_headTxt">Our Benefits</div>
                    <div className="benefit_heroTxt">Why Choose Us?</div>
                    <div className="benefitTxt">We're committed to helping you achieve your homeownership dreams with transparency and personalized support every step of the way. With our dedicated team of experts, we're here to guide you in securing the home loan that best suits your financial goals.
                    </div>
                    <ul className="benefitPoints">
                        <li className="benefitPoints">
                            <div className="benefitPointIcon">
                                <div className="benIcon01"></div>
                            </div>
                        </li>
                        <li className="benefitPoints"><div className="rightCurve"></div></li>
                        <li className="benefitPoints">
                            <div className="benefitPointHead">
                                Best Interest Rate
                            </div>
                            <div className="benefitPointText">
                                The interest rate you receive can significantly impact your finances. To secure the most favorable rate, it's essential to maintain a strong credit score. We can ensure you get the best interest rate available, allowing your money to work harder for you and helping you achieve your financial objectives.
                            </div>
                        </li>
                        <li className="benefitPoints">
                            <div className="benefitPointIcon">
                                <div className="benIcon02"></div>
                            </div>
                        </li>
                        <li className="benefitPoints"><div className="rightCurve"></div></li>
                        <li className="benefitPoints">
                            <div className="benefitPointHead">
                                Simple, Transparent and Secure
                            </div>
                            <div className="benefitPointText">
                                These three guiding principles define our commitment to providing an exceptional experience to our customers. We believe in making complex processes easy to understand and navigate, ensuring transparency in all our dealings and hassle-free experience that you can rely on.
                            </div>
                        </li>
                        <li className="benefitPoints">
                            <div className="benefitPointIcon">
                                <div className="benIcon03"></div>
                            </div>
                        </li>
                        <li className="benefitPoints"><div className="rightCurve"></div></li>
                        <li className="benefitPoints">
                            <div className="benefitPointHead">
                                Quick Payment Process
                            </div>
                            <div className="benefitPointText">
                                A quick payment process is essential in today's fast-paced digital world. It ensures that financial transactions are seamless and efficient, saving both time and effort for consumers. Additionally, it benefits from quicker access to funds and improved cash flow.
                            </div>
                        </li>
                    </ul>
                </li>
            </div>
        </ul>

    )
}

export default Component;