import { React, Helper, Container, TextInput, DropDown, ContactInfo, Support, TextArea } from "common";
import { CommonApi } from "services";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);

    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    // const [token] = useTimerSession('token');
    // const contentId = token ? 'content' : 'content_fullHeader';

    const OnBlurError = (name, val, clear) => {
        debugger;
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index, type) => {
        let newRow = row;
        if (type === 'ZIP') {
            for (var key in path) {
                if (Object.prototype.hasOwnProperty.call(path, key)) {
                    let _key = key.replace(/_/g, "");
                    path[_key] = path[key];
                    delete path[key];
                    newRow = Helper.ModifyJsonArrayObject4(newRow, _key, path[_key]);
                    newRow = Helper.ModifyJsonArrayObject4(newRow, `${_key}readonly`, !Helper.IsNullValue(path[_key]));
                }
            }
        } else {
            newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        }
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnReachUsClicked = async (e) => {
        e.preventDefault();
        console.log("row", row);
        if (!OnRecordValidate()) return;

        global.Busy(true);
        let rslt = await CommonApi.Contact(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
            setRow({});
        }
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_contact">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Get In Touch</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Contact Details</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBold_L">Please drop us a line, we would love to hear from you</div>
                                    <div className="pgTxtNormal">One of our loan officers will contact you for a brief consultation to find the best home loan solution for you.</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead_Error">Name</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormUserIcon"></div>
                                                <TextInput
                                                    path={`name`}
                                                    value={row?.name}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Name is required'}
                                                    dataRequired={true}
                                                    required={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormPhIcon"></div>
                                                <TextInput
                                                    path={`phone`}
                                                    value={row?.phone}
                                                    valuetype={"PHONE"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Phone Number is required'}
                                                    validationMessage={'Enter valid Phone Number'}
                                                    dataRequired={true}
                                                    required={true}
                                                    validate={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div>
                                                <div className="contactFormMailIcon"></div>
                                                <TextInput
                                                    path={`email`}
                                                    value={row?.email}
                                                    valuetype={"EMAIL"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Email-Id is required'}
                                                    validationMessage={'Enter valid Email-Id'}
                                                    validate={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Zip Code</div>
                                                <div className="contactFormHomeIcon"></div>
                                                <TextInput
                                                    errors={errors}
                                                    path={`zipcode`}
                                                    value={row?.zipcode}
                                                    valuetype={"ZIP"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    requiredMessage={'Pin Code is required'}
                                                    validationMessage={'Enter valid Pin Code'}
                                                    validate={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">City</div>
                                                <div className="contactFormLocationIcon"></div>
                                                <DropDown
                                                    path={`City`}
                                                    value={row?.City}
                                                    onInputChildChanged={OnInputChanged}
                                                    items={Support.CityTypes()}
                                                    displayName={"text"}
                                                    displayValue={"value"}
                                                    errors={errors}
                                                    indexValue={1}
                                                    requiredMessage={"City is required"}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Loan Type</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <DropDown
                                                    path={`loanType`}
                                                    value={row?.loanType}
                                                    onInputChildChanged={OnInputChanged}
                                                    items={Support.LoanTypes()}
                                                    displayName={"text"}
                                                    displayValue={"value"}
                                                    errors={errors}
                                                    indexValue={1}
                                                    requiredMessage={"loanType is required"}
                                                />
                                            </li>
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <TextArea
                                                path={`message`}
                                                value={row?.message}
                                                onInputChildChanged={OnInputChanged}
                                                onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked}
                                                errors={errors}
                                                dataRequired={false}
                                                required={false}
                                                requiredMessage={'Message is required'}
                                                validate={true}
                                            />
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input
                                                type="button"
                                                value="Reach Us"
                                                className="contactFormBtn"
                                                onClick={(e) => OnReachUsClicked(e)}
                                            />
                                        </div>
                                        {error && <div className="errormessage" >{error}</div>}

                                        {success && <div className="successmessage">Thank you for contacting us. We will get back to you ASAP.</div>}
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul >
                        </div >

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
