import { React, Header, Footer } from "../../common";

import {
    Landing, LandingBottom, AboutUs, USP,
    Benefits, Service
} from "./child";

const Component = () => {

    // const [showWip, setShowWip] = React.useState(false);

    // React.useEffect(() => {
    //     function handleClickOutside(event) {
    //         setShowWip(true);
    //     }
    //     // Bind the event listener
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    return (
        <>
            <Header />

            {/*Home Default View*/}
            <Landing />

            {/*Home Default View Bottom*/}
            <LandingBottom />

            {/*About Us*/}
            <AboutUs />
            {/* {showWip && <WorkInProgress onHideClicked={() => setShowWip(false)} />} */}

            {/*USP*/}
            <USP />

            {/* Benefits */}
            <Benefits />

            {/* Services */}
            <Service />

            {/*Footer*/}
            <Footer type={"Full"} />

        </>
    )
}

export default Component;