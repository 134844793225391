import React from "react";
import { Helper } from "helper";

const Component = (props) => {

    const { index, path, name, value, valuetype, maxLength, onBlurError, onInputClicked, onInputChildChanged,
        required, requiredMessage, validationMessage,
        readOnly, placeholder, errors, dataRequired, uppercase } = props;

    const [dataError, setDataError] = React.useState(false);

    let tPlceholder = placeholder || "";

    const OnInputClicked = (e) => {
        e.preventDefault();
        if (onInputClicked) {
            onInputClicked(e.target.name);
        }
    }

    const OnTextInputChanged = (e, formatVal) => {
        debugger;
        e.preventDefault();
        if (onInputChildChanged) {
            let value = formatVal ? formatVal : e.target.value;
            let _name = GetKeyName();
            if (uppercase && !Helper.IsNullValue(value)) value = value.toUpperCase();
            onInputChildChanged(_name, value, index);
        }
    };

    const RaiseOnBlurError = () => {
        global.Busy(false);
        let clear = true;
        let name = GetKeyName();
        if (onBlurError) {
            if (required) {
                setDataError(true);
            } else {
                setDataError(false);
            }
            if (required && Helper.IsNullValue(value)) onBlurError(name, requiredMessage)
            else onBlurError(name, null, clear)
        }
    };

    const GetKeyName = () => {
        let _name = `textarea`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    return (
        <>
            <textarea
                autoComplete={Helper.TimeStamp()}
                id={GetKeyName()}
                name={GetKeyName()}
                value={Helper.ToString(value)}
                maxLength={maxLength && maxLength}
                placeholder={Helper.ToString(tPlceholder)}
                onChange={(e) => OnTextInputChanged(e)}
                style={{
                    borderColor: Helper.GetErrorBackColor(errors, GetKeyName()),
                }}
                onKeyPress={(e) => {
                    return valuetype && Helper.AcceptedChars(e, valuetype);
                }}
                onBlur={(e) => RaiseOnBlurError(e)}
                onFocus={(e) => OnInputClicked(e)}
                readOnly={readOnly ? true : undefined}
                data-req={dataRequired}
                data-error={dataError}
                data-req-msg={requiredMessage}
                data-val-msg={validationMessage}
            >
            </textarea>
        </>
    )


}

export default Component;