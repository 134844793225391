import { Link } from "../../../../common";
import LoanContainer from "../loancontainer";

const Component = () => {
    return (
        <>
            <LoanContainer type={"fha"} header={"FHA Loans"}>
                <li className="loPgStructure">
                    <ul className="loPointsBlk">
                        <li className="loPointsBlk">
                            <ul className="loDescPoints">
                                <li className="loDescPoints">Down payment as low as 3.5%</li>
                                <li className="loDescPoints">Include all costs in one loan</li>
                                <li className="loDescPoints">Steady, affordable monthly payments</li>
                                <li className="loDescPoints">Qualify for higher loan amounts</li>

                                <Link to="#"><input type="button" value="Apply Now" className="loButton" /></Link>
                            </ul>
                        </li>
                        <li className="loPointsBlk">
                            <div className="pgTxtNormal">In order to promote home ownership, the Federal Housing Administration (FHA) offers FHA loans, which are backed by the FHA and provided by FHA-approved lenders. It is one type of federal assistance that has helped homeowners since 1934. With less stringent guidelines, lenders are able to issue loans on mortgages that wouldn't normally fit conventional underwriting requirements, allowing more people to become homeowners. They tend to be more lenient on areas such as credit, funds to close and co-borrowers. At the same time, lenders are protected from losses suffered if the home goes into foreclosure because these loans are insured by the federal government.</div>
                            <div className="pgTxtNormal">An FHA loan may be right for you if:</div>
                            <ul className="bulletPoints_un">
                                <li className="bp_circleOutline"><b>You are buying your first home.</b> <br />Your down payment can be as low as 3.5% of the purchase price, and most of your closing costs and fees can be included in the loan. This is available on 1-4 unit properties.</li>
                                <li className="bp_circleOutline"><b>You want a fixer-upper.</b> <br />FHA has a loan that allows you to buy a home, fix it up, and include all the costs in one loan. Or, if you own a home that you want to re-model or repair, you can refinance what you owe and add the cost of repairs - all in one loan.</li>
                                <li className="bp_circleOutline"><b>You are a senior living in a home that you own or with a low loan balance.</b> <br />People fitting this profile may be well-suited for an FHA Reverse Mortgage might be right for you. It lets you convert a portion of your equity into cash.</li>
                                <li className="bp_circleOutline"><b>You want to make your home more energy efficient.</b><br />You can include the costs of energy improvements into an FHA Energy-Efficient Mortgage.</li>
                                <li className="bp_circleOutline"><b>You need financing for mobile homes and factory-built housing.</b> <br />FHA offers two loan products - one for those who own the land that the home is on and another for mobile homes that are - or will be - located in mobile home parks.</li>
                            </ul>
                            <div className="pgTxtBold">A Unique Solution</div>
                            <div className="pgTxtNormal">Unlike most loan programs, FHA does not have specific credit score requirements. Although a high credit score may assist in getting the mortgage approved, a low score is not automatically cause for denial. If the credit scores are low, then it is up to the borrower to demonstrate his/her ability and willingness to pay the loan back. This allows the borrower to explain the circumstances surrounding the credit difficulties and have that explanation considered in the underwriting process.</div>
                            <div className="pgTxtNormal">Because of FHA’s leniency, some borrowers with past credit problems elect to use FHA for loans when they have a substantial down payment rather than getting a higher interest rate conventional loan. FHA tends to be more flexible than Conventional financing in the money needed to purchase the home.</div>
                            <div className="pgTxtNormal">In an FHA mortgage the customer must put at least 3% of the sales price into the transaction. Some of this money may be used for down payment and the rest for closing costs . Keep in mind, however, that the total cost to close on an FHA is commonly over 3%. With the down payment, closing costs, money to establish escrows for taxes and insurance plus interest to finish out the month of closing, the total costs can be closer to 6 or 8% of the sales price.</div>
                            <div className="pgTxtNormal">The interest rate that you select will also have a bearing on the total costs. If you select a lower rate so that you can reduce your payment, you may end up paying additional money towards “points”. At the same time if you are comfortable with a slightly higher payment you may find a lender that is willing to reduce the costs to close in favor of a higher interest rate.</div>
                            <div className="pgTxtNormal">FHA allows the borrower to get the funds necessary to close from several sources. They include such areas as personal savings, gifts, grants, loans from retirement accounts and seller contributions.</div>
                            <div className="pgTxtBold">Required Disclosure</div>
                            <div className="pgTxtNormal">These materials are not from HUD or FHA and were not approved by HUD or a government agency.</div>
                        </li>
                    </ul>
                </li>
            </LoanContainer>
        </>
    )
}

export default Component;