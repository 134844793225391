import { Link } from "react-router-dom";

const Component = () => {
    return (

        <div id="homeBgImg">
            <div className="widthfix_10px">
                <div className="homeTxt_blk">
                    <div className="mainTxt">competitive  <br />Non-QM  mortgage  rate  guaranteed</div>
                    <div className="supportTxt">We offer the assistance you need to help in all of your mortgage endeavors. Whether you are consolidating your debt, refinancing your home or buying your first house, we have the expertise to make it a fast, easy and enjoyable process.</div>
                    <Link to="/nonqm"><input type="button" className="more_button" value="Learn More" /></Link>
                </div>
            </div>
        </div>

    )
}

export default Component;