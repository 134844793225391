import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_buy">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Build New Home</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">When you build a new home, you'll have different considerations than purchasing an existing home.You should research the developer or builder's track record before you sign the purchase contracts. Financing new construction also has its own special set of requirements and questions:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">When can I secure a construction loan?</li>
                                        <li className="bp_circleOutline">How is a construction loan structured?</li>
                                        <li className="bp_circleOutline">How do I coordinate selling my current home to finance my new construction?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage application to closing?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div >
                </div >
            </Container>
        </>
    )
}

export default Component;