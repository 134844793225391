import { React, Link } from "../../../../common";
import Container from "../../../container";

// import { LoanTabsListNav } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_options">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Loan Options</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">VA Loans</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="loPgStructure">
                                <li className="loPgStructure">
                                    {/* <LoanTabsListNav type="va" /> */}
                                </li>
                                <li className="loPgStructure">
                                    <ul className="loPointsBlk">
                                        <li className="loPointsBlk">
                                            <ul className="loDescPoints">
                                                <li className="loDescPoints">No money down up to $766,550</li>
                                                <li className="loDescPoints">No PMI (Private Mortgage Insurance)</li>
                                                <li className="loDescPoints">Loans can be closed in 7 to 10 days</li>
                                                <li className="loDescPoints">Qualify for higher loan amounts</li>

                                                <Link to="#"><input type="button" value="Apply Now" className="loButton" /></Link>
                                            </ul>
                                        </li>
                                        <li className="loPointsBlk">
                                            <div className="pgTxtNormal">Siddhi Financials specializes in VA loans, which are guaranteed by the Department of Veterans Affairs (VA). These loans were established to provide transition assistance and other benefits to men and women who served or are serving in the Armed Forces of the Nation. This includes the Army, Navy, Air Force, Marines, Reservists, National Guardsmen, and certain surviving spouses. Also, if you are a disabled veteran, you may qualify for additional benefits on a VA home mortgage loan.</div>
                                            <div className="pgTxtBold">Benefits of a VA Loans:</div>
                                            <ul className="bulletPoints_un">
                                                <li className="bp_circleOutline">No money down for loans up to $766,550</li>
                                                <li className="bp_circleOutline">No PMI (Private Mortgage Insurance)</li>
                                                <li className="bp_circleOutline">Government limits the amount of closing costs and origination fees lenders can charge, as well as appraisal fees</li>
                                                <li className="bp_circleOutline">Streamlined refinance loans can be closed in 7 to 10 days</li>
                                                <li className="bp_circleOutline">Protection built into ARM products that caps the annual allowable rate increase</li>
                                                <li className="bp_circleOutline">There is no limit to the number of times a veteran may use the program</li>
                                            </ul>
                                            <div className="pgTxtNormal">In addition to these benefits, the VA Home Loan program offers default assistance to veteran borrowers in financial difficulty through a higher level of service and a greater range of alternatives to avoid foreclosure. Delinquency and foreclosure rates for VA loans are substantially less than for FHA loans</div>
                                            <div className="pgTxtBold">The VA Funding Fee</div>
                                            <div className="pgTxtNormal">The Veterans Administration assessed a Funding Fee to all VA loans between .5% and 3.3% of the loan amount. The fee is added into the amount of the loan to be paid over the life of your VA home mortgage loan. The VA Funding Fee replaces the much higher priced Mortgage Insurance required when you get a conventional home loan. If you are a Disabled Veteran, you may qualify to get the fee waived completely.</div>
                                            <div className="pgTxtBold">Qualifying for a VA Mortgage Loan</div>
                                            <div className="pgTxtNormal">The VA offers excellent qualifying standards. The VA does not use credit scoring in their analysis of the loan. Even if you have experienced some financial difficulties in your life that caused your scores to be low but have maintained a good payment record over the past year or so, you may qualify for a VA mortgage loan. This can be a tremendous savings compared to the cost of conventional loans when the borrower’s credit scores are low.</div>
                                            <div className="pgTxtBold">VA Mortgage Loans may be refinanced</div>
                                            <div className="pgTxtNormal">VA mortgage loans have built in features allowing a loan to be refinanced to a lower interest rate without all of the criteria normally associated with a conventional loan. This is called an Interest Rate Reduction Loan; the veteran can secure a lower interest rate without any credit checks, appraisal, and income or asset verification and can roll the costs of the transaction into the loan so there are no out of pocket costs.</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;