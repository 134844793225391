import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";

// BUY
import {
  BuyOverview, BuyFirstHome, BuyNextHome, BuySecondHome, BuildNewHome, Investment, BuyForeclosed
} from "../screens/headertabs/buy";

//Refinance Links
import {
  LowerPayment, ReduceRate, PayOff,
  Convert, Consolidate, PrepareRefinance, RefinanceSteps
} from "../screens/headertabs/refinance";

//Mortgage Tools
import {
  LoanProcess, MortgageRates, MortgageCalculator, RefinanceCalculator,
} from "../screens/headertabs/mortgagetools";

//Loan Options Links
import {
  ThirtyYr, FHA, VA, Reverse, USDA, Jumbo,
  Renovation, NonQM, Conventional
} from "../screens/headertabs/loanoptions";

// Generic Page Links
import {
  About, Career, Blog, Review, Contact,
  BecomeBranch, FindLoanOfficer, Terms,
  Privacy, Disclosure, Branch
} from "../screens/headertabs/generic";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "buyoverview", Component: BuyOverview },
  { id: 3, path: "firsthome", Component: BuyFirstHome },
  { id: 4, path: "nexthome", Component: BuyNextHome },
  { id: 5, path: "secondhome", Component: BuySecondHome },
  { id: 6, path: "buildnewhome", Component: BuildNewHome },
  { id: 7, path: "investment", Component: Investment },
  { id: 8, path: "foreclosed", Component: BuyForeclosed },
  { id: 9, path: "lowerpayment", Component: LowerPayment },
  { id: 10, path: "reducerate", Component: ReduceRate },
  { id: 11, path: "payoff", Component: PayOff },
  { id: 12, path: "convert", Component: Convert },
  { id: 13, path: "consolidate", Component: Consolidate },
  { id: 14, path: "prepare", Component: PrepareRefinance },
  { id: 15, path: "steps", Component: RefinanceSteps },
  { id: 16, path: "loanprocess", Component: LoanProcess },
  { id: 17, path: "mortgagerates", Component: MortgageRates },
  { id: 18, path: "mortgagecalculator", Component: MortgageCalculator },
  { id: 19, path: "refinancecalculator", Component: RefinanceCalculator },
  { id: 20, path: "thirty", Component: ThirtyYr },
  { id: 21, path: "fha", Component: FHA },
  { id: 22, path: "va", Component: VA },
  { id: 23, path: "reverse", Component: Reverse },
  { id: 24, path: "usda", Component: USDA },
  { id: 25, path: "jumbo", Component: Jumbo },
  { id: 26, path: "renovation", Component: Renovation },
  { id: 28, path: "conventional", Component: Conventional },
  { id: 29, path: "nonqm", Component: NonQM },
  { id: 30, path: "about", Component: About },
  { id: 31, path: "career", Component: Career },
  { id: 32, path: "blogs", Component: Blog },
  { id: 33, path: "reviews", Component: Review },
  { id: 34, path: "contact", Component: Contact },
  { id: 35, path: "becomebranch", Component: BecomeBranch },
  { id: 36, path: "findlo", Component: FindLoanOfficer },
  { id: 37, path: "terms", Component: Terms, },
  { id: 38, path: "privacy", Component: Privacy },
  { id: 39, path: "disclosure", Component: Disclosure },
  { id: 40, path: "branch", Component: Branch },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return <Route key={x.id} path={"/" + x.path} exact
            element={
              <PrivateRoute>
                <x.Component />
              </PrivateRoute>
            }
          />
        } else {
          return <Route key={x.id} path={"/" + x.path} exact
            element={<x.Component />} />
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;