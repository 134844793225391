const CityTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Alabama", value: "Alabama" },
    { id: 2, text: "California", value: "California" },
    { id: 3, text: "Florida", value: "Florida" },
  ];
};

const LoanTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Purchase", value: "Purchase" },
    { id: 2, text: "Refinance", value: "Refinance" },
    { id: 3, text: "Others", value: "Others" },
  ];
};

const StateLicencingTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Alabama", value: "Alabama" },
    { id: 2, text: "California", value: "California" },
    { id: 3, text: "Florida", value: "Florida" },
  ];
};

const HowDoYouDescribeTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Sales Professional", value: "Sales Professional" },
    { id: 2, text: "Large Group Manager", value: "Large Group Manager" },
  ];
};

export {
  CityTypes, LoanTypes, StateLicencingTypes, HowDoYouDescribeTypes
};
