const Component = () => {
    return (
        <div id="info_fixedtwenty" className="pop_disable_bg">
            <div className="thirtyFixed_pop">
                <div className="pop_head_bgRed">20-Year Fixed</div>
                <div className="pop_contentArea">
                    <div className="pop_content">Fixed interest rate for 20 years, giving homebuyers the security of a predictable monthly payment.</div>
                </div>
                <div className="pop_buttonLabel_borderTop_Red">
                    <input type="button" value="Close" className="pop_button_bgRed" onClick={() => window.hide('info_fixedtwenty')} />
                </div>
            </div>
        </div>
    );
};

export default Component;