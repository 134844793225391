import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refinance">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Mortgage Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Reduce Your Current Rate</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">When mortgage rates fall, you can take advantage of the opportunity to refinance to a lower rate. Lower rates can help you accomplish a number of financial goals:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">If you refinance the same loan term (for example, 30-year) at a lower rate, you’ll have lower monthly payments. Be sure to review the total interest paid on the amount you originally borrowed and the refinanced amount to ensure that you are comfortable with this overall expense.</li>
                                        <li className="bp_circleOutline">You can take advantage of lower rates and switch to a shorter-term loan, allowing you to pay off your loan sooner. You may have a higher monthly payment, but your total interest payment will be reduced significantly by switching to a shorter-term mortgage.</li>
                                        <li className="bp_circleOutline">An Adjustable-Rate Mortgage (ARM) lets you take advantage of lower rates in the market as they occur. If your ARM rate adjusts higher than you want, you may be able to refinance to a more favorable ARM or fixed-rate mortgage.</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;