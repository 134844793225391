import { React, Link, useNavigate } from "common.js";

const tabItems = [
    { name: "purchase", link: "#", icon: "lo_icon1", iconActive: "lo_icon1_active", label: "Purchase New House" },
    { name: "refinancing", link: "#", icon: "lo_icon2", iconActive: "lo_icon2_active", label: "Refinancing Your Home" },
    { name: "capital", link: "#", icon: "lo_icon3", iconActive: "lo_icon3_active", label: "Capital Management" },
    { name: "house", link: "#", icon: "lo_icon4", iconActive: "lo_icon4_active", label: "House Renovation" },
    { name: "refinance", link: "#", icon: "lo_icon5", iconActive: "lo_icon5_active", label: "Refinance Advisor" },
    { name: "prequalify", link: "#", icon: "lo_icon6", iconActive: "lo_icon6_active", label: "Pre-Qualify For Loan" },
]

const Component = ({ type, home, onTabClicked }) => {
    const [activate, setActivate] = React.useState(type);
    const NavigateTo = useNavigate();

    const OnTabNavigate = (e, name, link) => {
        e.preventDefault();
        if (home) {
            setActivate(name);
            onTabClicked(`id${name}`);
            return;
        }
        return NavigateTo(link);
    };

    return (
        <div>
            <ul className="loIconNavi">
                {
                    tabItems.map((x) => {
                        if (x.name === activate) {
                            return (
                                <li className="loIconNavi" key={x.name}>
                                    <Link onClick={(e) => OnTabNavigate(e, x.name, x.link)} to={x.link} className="current"><div className={x.iconActive}></div>{x.label}</Link>
                                </li>
                            )
                        } else {
                            return (
                                <li className="loIconNavi" key={x.name}>
                                    <Link onClick={(e) => OnTabNavigate(e, x.name, x.link)} to={x.link}><div className={x.icon}></div>{x.label}</Link>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </div >
    )
}

export default Component;