import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refinance">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Convert to Fixed Rate Loan</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">An Adjustable-Rate Mortgage (ARM) can be a good choice if you plan to stay in your home for a short time or if you want more manageable payments upfront.</div>
                                    <div className="pgTxtNormal">If you find you’ll be living in your home longer, or if your ARM adjusts to a rate that’s higher than current conventional mortgage rates, refinancing to a fixed-rate mortgage may make sense.</div>
                                    <div className="pgTxtNormal"><b>Example:</b><br />The Smiths purchased a home for $200,000 with a 5/1 ARM as they originally intended to sell the property before the initial fixed-period ends. After 3 years, they decided to stay longer and refinanced to a 30-year fixed-rate mortgage.</div>
                                    <div className="refin_4col_fixedRate">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Loan Amount</td>
                                                    <td>Interest Rate</td>
                                                    <td>Monthly Principal And Interest (P&I) Payment</td>
                                                    <td>Principal Balance At 3 Years</td>
                                                </tr>
                                                <tr>
                                                    <td>$150,000</td>
                                                    <td>ARM 2.250%</td>
                                                    <td>$573</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>Maximum rate at 1st payment</td>
                                                    <td>ARM 4.250%</td>
                                                    <td>$753</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>Refinance $139,131 to a 30-year fixed-rate</td>
                                                    <td>ARM 3.750%</td>
                                                    <td>$615</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtNormal">*Principal balance assumes that the borrower will pay all closing costs out of pocket.</div>

                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;