import { React, Header, Footer } from "../common";

const Component = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

export default Component;