import { React, Link } from "../../../../common";
import Container from "../../../container";
import { InfoFixedThirty } from "./popup";
import { InfoFixedThirtyFHA } from "./popup";
import { InfoFixedTwenty } from "./popup";
import { InfoFixedFifteen } from "./popup";

const Component = () => {

    const OnGettingStarted = async (e) => {
        e.preventDefault();
        document.getElementById("ziplook_pop").style.display = "block";
    };

    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_tools">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Mortgage Rates</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol">
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Purchase</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>Dec/20/2023</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.99<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.22<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.875<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.105<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.375<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.605<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.50<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.73<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed FHA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.125<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.355<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed VA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.625<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.855<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed VA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.250<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.48<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed USDA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.625<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.855<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                    </div>
                                </li>
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Refinance</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>Dec/20/2023</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.99<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.22<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.875<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.105<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.375<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.605<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.50<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.73<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed FHA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.125<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.355<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed VA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.625<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.855<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed VA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.250<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.48<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed USDA</div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">5.625<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">5.855<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>

            <InfoFixedThirty />
            <InfoFixedThirtyFHA />
            <InfoFixedTwenty />
            <InfoFixedFifteen />
        </>
    )
}

export default Component;
