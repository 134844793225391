import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div id="info_fixedthirtyFHA" className="pop_disable_bg">
            <div className="thirtyFixed_pop">
                <div className="pop_head_bgRed">30-Year Fixed FHA</div>
                <div className="pop_contentArea">
                    <div className="pop_content">Ideal mortgage type for homebuyers with a lower credit score or looking for a low down payment.<br /><br /><Link to="/fha">Click Here</Link> to know more about FHA loans.</div>
                </div>
                <div className="pop_buttonLabel_borderTop_Red">
                    <input type="button" value="Close" className="pop_button_bgRed" onClick={() => window.hide('info_fixedthirtyFHA')} />
                </div>
            </div>
        </div>
    );
};

export default Component;