import { React, Link } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    const hoNJClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.google.com/maps/place/3916+Bergenline+Ave+%232222,+Union+City,+NJ+07087,+USA/@40.7761671,-74.0285863,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25821348d89a7:0xd910732b16ce3d45!8m2!3d40.7761631!4d-74.0260114?entry=ttu";
        window.open(_link, "_blank");
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_generic">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Branch Locations</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="branchLocationBlk">
                                <ul className="IIIcol">
                                    <li className="IIIcol">
                                        <div className="blContentBox">
                                            <div className="bl_head">Corporate Office</div>
                                            <div className="bl_city">New Jersey</div>
                                            <div className="bl_content"> 3916 Bergenline Ave Suite 2222 Union City
                                                <div className="state">New Jersey 07087</div>
                                                <ul className="locIconDirection">
                                                    <li className="locIconDirection"></li>
                                                    <li className="locIconDirection"><Link to="" onClick={hoNJClicked}>Location</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
