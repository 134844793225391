const Component = () => {
    return (

        <div id="home_bottom_blk">
            <div className="widthfix_10px">
                <ul className="home_III_col">
                    <li className="home_III_col">
                        <div className="bottomIcon1"></div>
                        <div className="bottomText">Best Rates on <br />All Loans</div>
                    </li>
                    <li className="home_III_col">
                        <div className="bottomIcon2"></div>
                        <div className="bottomText">99.9% Success<br />Rate Guarantee</div>

                    </li>
                    <li className="home_III_col">How much do you need</li>
                </ul>
            </div>
        </div>

    )
}

export default Component;