import axios from "axios";
import { UploadApi } from "services";

var fn = {};

fn.Upload = async (file, obj) => {
  return new Promise(async (resolve) => {
    global.Busy(true);
    let tmpFile = `${obj.nfileName}.${obj.ext}`;
    let rslt = await UploadApi.GetSignedUrl({ fileName: tmpFile });
    if (rslt.status !== 100) {
      global.Busy(false);
      return resolve(rslt);
    }

    let urlInfo = rslt.data;
    rslt = await UploadToBucket(urlInfo.url, file, urlInfo.ContentType);
    if (rslt.status !== 100) {
      global.Busy(false);
      return resolve(rslt);
    }

    global.Busy(false);
    return resolve({ status: 100 });

  });
};

const UploadToBucket = async (url, data, type) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": type,
    },
  };
  try {
    const res = await axios.put(url, data, headers);
    if (res.status === 200) {
      return { status: 100, data: res.request.responseURL };
    }
    return { statusText: res.statusText, status: 900 };
  } catch (err) {
    console.log(err);
    return { statusText: err.message, status: 900 };
  }
};

export default fn;
