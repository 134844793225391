import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {
    const OnGettingStarted = async (e) => {
        e.preventDefault();
        document.getElementById("ziplook_pop").style.display = "block";
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_tools">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Mortgage Process</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="loanProcessBlk">
                                <div className="loanProcessHead">The mortgage process: Simpler than you think</div>
                                <ul className="IIIcol">
                                    <li className="IIIcol">
                                        <ul className="lp_headblk">
                                            <li className="lp_headblk">
                                                <div className="lp_leftCurveBlk"><div className="lp_leftCurve"></div></div>
                                            </li>
                                            <li className="lp_headblk">Pre-Qualify</li>
                                            <li className="lp_headblk">
                                                <div className="lp_rightCurveBlk"><div className="lp_rightCurve"></div></div>
                                            </li>
                                        </ul>
                                        <div className="lpContentBox">
                                            <div className="lpContent">Getting prequalified lets you know how much you can borrow, and lets sellers know you can back up your offer</div>
                                            <div className="lpBtnBlk">
                                                <input type="button" value="Get Pre-Qualified" className="lpButton" onClick={(e) => OnGettingStarted(e)} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <ul className="lp_headblk">
                                            <li className="lp_headblk">
                                                <div className="lp_leftCurveBlk"><div className="lp_leftCurve"></div></div>
                                            </li>
                                            <li className="lp_headblk">Fill 1003 Form</li>
                                            <li className="lp_headblk">
                                                <div className="lp_rightCurveBlk"><div className="lp_rightCurve"></div></div>
                                            </li>
                                        </ul>
                                        <div className="lpContentBox">
                                            <div className="lpContent">Fill the Fannie Mae 1003 Mortgage Application Form with Borrower and Co-Borrower details</div>
                                            <div className="lpBtnBlk">
                                                <input type="button" value="Fill Online" className="lpButton" onClick={() => window.fnSign(1)} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <ul className="lp_headblk">
                                            <li className="lp_headblk">
                                                <div className="lp_leftCurveBlk"><div className="lp_leftCurve"></div></div>
                                            </li>
                                            <li className="lp_headblk">Apply for Loan</li>
                                            <li className="lp_headblk">
                                                <div className="lp_rightCurveBlk"><div className="lp_rightCurve"></div></div>
                                            </li>
                                        </ul>
                                        <div className="lpContentBox">
                                            <div className="lpContent">It's time to submit a mortgage application and work with us to complete the final steps to becoming a homeowner</div>
                                            <div className="lpBtnBlk">
                                                <input type="button" value="Get Started" className="lpButton" onClick={(e) => OnGettingStarted(e)} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
