import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    /* const smlTexasClick = () => {
        window.open("https://www.sml.texas.gov", "_blank");
    };

    const OnNmlsLookupClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1020884";
        window.open(_link, "_blank");
    }; */

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_generic">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Disclosures and Licenses</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_L">Siddhi Financials Disclosures and License Information</div>
                                <div className="pgTxtNormal">Siddhi Financials is an Equal Housing Lender. As prohibited by federal law, we do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.</div>
                            </div>
                            <div className="pgTxtBold">Siddhi Financials State Disclosure Requirements</div>
                            <div className="pgTxtNormal">Siddhi Financials LLC. is a licensed mortgage lender. <br />The following states require disclosure of licensing information. (If your state is not listed, it doesn't require a specific license disclosure):</div>
                            <ul className="bulletPoints_un">
                                <li className="bp_circleOutline"><b>Florida :</b> Mortgage Lender License# - MLD2412.</li>
                                <li className="bp_circleOutline"><b>New Jersey :</b> Loans made or arranged by third parties. Licensed by the N.J. Department of Banking and Insurance. Correspondent Residential Mortgage Lender License NMLS# – 1020884.</li>
                                <li className="bp_circleOutline"><b>California :</b> Pending approval</li>
                                <li className="bp_circleOutline"><b>Virginia :</b> In process</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
