import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refinance">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Prepare For Refinancing</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBoldNoTopSpace">How do I prepare for refinancing?</div>
                                    <div className="pgTxtNormal">You will likely be familiar with most of the steps required during your refinancing, from your mortgage loan application to the closing of your new mortgage loan. The sooner you complete the application process, the sooner you can complete your refinancing and start enjoying a lower rate.</div>
                                    <div className="pgTxtNormal">There are some points that you’ll want to keep in mind that can affect your ability to refinance and the amount of money you’ll be able to access, if you’re interested in a cash-out refinance.</div>
                                    <div className="pgTxtBold">Loan-to-Value (LTV) Ratio</div>
                                    <div className="pgTxtNormal">Often referred to as the LTV, this expresses the equity you have in your home as a ratio of the loan amount to the appraised value of your home. The maximum LTV is 80% for mortgages without private mortgage insurance. If your home has dropped in value, resulting in an increase in your LTV, private mortgage insurance may be required or you may choose not to refinance </div>
                                    <div className="refin_3col_prepare">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Current Mortgage Amount</td>
                                                    <td>Current Home Appraisal</td>
                                                    <td>LTV</td>
                                                </tr>
                                                <tr>
                                                    <td>$150,000</td>
                                                    <td>$200,000</td>
                                                    <td>75%</td>
                                                </tr>
                                                <tr>
                                                    <td>$100,000</td>
                                                    <td>$200,000</td>
                                                    <td>50%</td>
                                                </tr>
                                                <tr>
                                                    <td>$50,000</td>
                                                    <td>$200,000</td>
                                                    <td>25%</td>
                                                </tr>
                                                <tr>
                                                    <td>$150,000</td>
                                                    <td>$180,000</td>
                                                    <td>83%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtBold">Equity</div>
                                    <div className="pgTxtNormal">If you’re interested in a cash-out refinance, the more equity you have in your current home, the lower your LTV will be – resulting in more cash being available to you.</div>
                                    <div className="pgTxtBold">Credit Score</div>
                                    <div className="pgTxtNormal">Your current credit score will affect the maximum LTV allowed for your refinance. So, take some time to get free copies of your credit report* from all 3 major reporting companies and correct any inaccuracies. Also, hold off on making any major purchases until after your refinancing is completed, as taking on significant debt now can impact your loan decision. Use our checklist and start collecting the documents you’ll need to apply for your refinancing.</div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;