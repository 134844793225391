import API from "./api";

const GetSignedUrl = async (input) => {
    try {
        const res = await API.post(`doc/getsignedurl`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetSignedUrl };