import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_review">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Reviews &amp; Feedback</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="reviewBlk">
                                <ul className="IIcol_Review">
                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkLeft">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentLeft">ME
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                                <li className="reviewPhotoContentLeft">
                                                    <div className="reviewerName">MARY ELIZABETH</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">September 10, 2023</li>
                                                    </ul>
                                                    <div className="reviewDesc">Outstanding Experience!</div>
                                                    <div className="reviewContent">Outstanding service from beginning to the end. Siddhi's customer service skills were top notch. They made buying a house so easy. We literally closed on our home less than 30 days with their amazing knowledge of getting us approved. Siddhi financials employees are amazing!</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowLeft" >
                                            <li className="reviewCurveArrowLeft">
                                                <div className="reviewLeftCurve"></div>
                                            </li>
                                            <li className="reviewCurveArrowLeft">&nbsp;</li>
                                        </ul>
                                    </li>

                                    <li className="IIcol_Review">
                                        <div className="reviewContentBlkRight">
                                            <ul className="reviewPhotoContent">
                                                <li className="reviewPhotoContentRight">
                                                    <div className="reviewerName">RAFEEQ PARKMAN</div>
                                                    <ul className="ratingDateBlk">
                                                        <li className="ratingDateBlk">
                                                            <div className="rating_sprite_red"><span className="rating_sprite_red_bg" style={{ width: "100%" }}></span></div>
                                                        </li>
                                                        <li className="ratingDateBlk">October 06, 2023</li>
                                                    </ul>
                                                    <div className="reviewDesc">Greta Experience</div>
                                                    <div className="reviewContent">Torres answered all of our questions and helped us stay very realistic to what we can afford. I’ve already recommended my friend to you guys!</div>
                                                </li>
                                                <li className="reviewPhotoContentRight">RP
                                                    {/* <div className="reviewerPhoto"><img src="#" alt="" /></div> */}
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="reviewCurveArrowRight" >
                                            <li className="reviewCurveArrowRight">
                                                &nbsp;
                                            </li>
                                            <li className="reviewCurveArrowRight">
                                                <div className="reviewRightCurve"></div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
