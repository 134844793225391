const Component = ({ onClose }) => {

  const OnCloseClicked = (e) => {
    if (onClose) onClose(e);
  }

  const OnSubmitClicked = (e) => {
    e.preventDefault();
    if (onClose) onClose(e);
  }

  return (
    <div id="rateQuote_pop" className="pop_disable_bg" style={{ display: "block" }}>
      <div className="rateQuote_pop">
        <div className="rateQuote_ContentArea">
          <div className="rateQuote_Head">Get a Rate Quote</div>
          Content
          <div className="rq_button_label">
            <input type="button" className="rateQuoteBut" value="Submit" onClick={(e) => OnSubmitClicked(e)} />
            <input
              type="button"
              className="rateQuoteBut"
              value="Close"
              onClick={(e) => OnCloseClicked(e)}
            />
          </div>
          <div className="errormessage_center" style={{ display: "none" }}>
            All fields are mandatory
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
