import React from "react";
import RendorRoutes from "./routes";

global.Busy = (bBusy) => {
  var x = document.getElementById("busyloader");
  if (x) {
    if (bBusy) {
      x.className = "loader display-block";
    } else {
      x.className = "loader display-none";
    }
  }
};

function App() {
  return <RendorRoutes />;
}

export default App;