import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_generic">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">About Us</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <ul className="aboutInpgContainer">
                                    <li className="aboutContainer">
                                        <div className="aboutImgBlk">
                                            <div className="expBlk">
                                                <div className="expBlkContent">
                                                    <div className="txtExpNumber">10<span className="symbolFont">&#43;</span></div>
                                                    <div className="txtExp">Years Experience</div>
                                                </div>
                                            </div>
                                            <div className="aboutImage"></div>
                                        </div>
                                    </li>
                                    <li className="aboutContainer">
                                        <div className="aboutContentBlk">
                                            <div className="abt_headTxt">About Us</div>
                                            <div className="abt_heroTxt">Smart solutions.<br />Simple process.</div>
                                            <div className="pgTxtNormal">You can trust us to provide affordable, flexible home lending solutions that fit your unique, real-life needs. Our simple products, pricing and process make it easy for you to get home financing.</div>
                                            <div className="pgTxtNormal">It’s easy to find out when interest rates reach your target. With Free Rate Watch, we keep an eye on interest rates so you don’t have to – and we’ll e-mail you when rates reach your goal. We’ll be here for you long after your loan closes, ready to assist should your home borrowing needs change in the future. So, rest easy knowing you have our support by phone and at your local Siddhi Financials when you need it.</div>

                                        </div>

                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
