import ApplyForLoan from "./applyforloan";

const Component = () => {
    return (

        <ul className="aboutContainer">
            <div className="widthfix_10px">
                <li className="aboutContainer">
                    <div className="abt_heroTxt">Innovation, Inspiration and Integrity</div>
                    <div className="abt_pointHeadTxt">First is Innovation.</div>
                    <div className="abt_pointTxt">This company is innovative in bringing the latest technology, the best rates
                        and service possible.</div>
                    <div className="abt_pointHeadTxt">Second is Inspiration.</div>
                    <div className="abt_pointTxt">Our block chain innovation inspires all the possibilities in helping others to get
                        their dream home.</div>
                    <div className="abt_pointHeadTxt">Third is Integrity.</div>
                    <div className="abt_pointTxt">With those inspired we are guided by Integrity ensuring we are helping our
                        clients for a lifetime.</div>
                    <ul className="abt_uspPoint">
                        <li className="abt_uspPoint">Solid Team</li>
                        <li className="abt_uspPoint">Faster Process</li>
                        <li className="abt_uspPoint">Easy Documentation</li>
                        <li className="abt_uspPoint">24/7 Online Support</li>
                    </ul>
                </li>
                <li className="aboutContainer">
                    <div className="howMuchHeadText">How much do you need</div>
                    <ApplyForLoan />
                    {/* <div className="setLoanAmt"></div> */}
                    <input type="button" className="apply_button" value="Apply For Loan" />
                </li>
            </div>
        </ul>

    )
}

export default Component;