import { React, Link } from "../../../../common";
import Container from "../../../container";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_generic">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Find Loan Officer</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="lo_pgBlk">
                                <ul className="filterLo">
                                    <li className="filterLo">
                                        <div className="selectFilterLO">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>City</option>
                                                <option value="#">New York</option>
                                                <option value="#">Los Angeles</option>
                                                <option value="#">Chicago</option>
                                                <option value="#">Houston</option>
                                            </select>
                                        </div>
                                    </li>
                                    <div className="filterLo_but_blk">
                                        <input type="button" value="Search" className="filterLo_but" />
                                    </div>
                                </ul>

                                <div className="loResults" style={{ display: "none" }}>Showing Loan Officers for Los Angeles, California </div>

                                <ul className="lo_display_blk" style={{ display: "none" }}>
                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details"><Link to="#" title="FL, FL,FL, FL,FL, FL,FL, FL,FL, FL,FL, FL, FL, FL">FL, FL,FL, FL,FL, FL,FL, FL,FL, FL,FL, FL, FL, FL</Link></li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>

                                    <li className="lo_display_blk">
                                        <div className="loName">Edwina Janelle Mack</div>
                                        <ul className="lo_details">
                                            <li className="lo_details">Work Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Cell Phone:</li>
                                            <li className="lo_details">843-325-5466</li>
                                            <li className="lo_details">Email:</li>
                                            <li className="lo_details">mack@i3lending.com</li>
                                            <li className="lo_details">NMLS ID:</li>
                                            <li className="lo_details">1750011</li>
                                            <li className="lo_details">State:</li>
                                            <li className="lo_details">FL</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
