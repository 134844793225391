import { React, Helper, Container, TextInput, DropDown, ContactInfo, Support } from "common";
import { CommonApi } from "services";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);

    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);


    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnApplyNowClicked = async (e) => {
        console.log("row", row);
        e.preventDefault();
        if (!OnRecordValidate()) return;

        global.Busy(true);
        let rslt = await CommonApi.Contact(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
            setRow({});
        }
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_contact">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Become a Branch</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBoldNoTopSpace">No more searching online and filling out applications for hours. Just fill this online form and join our ever growing list of Loan Officers.</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead_Error">Name</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormUserIcon"></div>
                                                <TextInput
                                                    path={`name`}
                                                    value={row?.name}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Name is required'}
                                                    dataRequired={true}
                                                    required={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormMailIcon"></div>
                                                <TextInput
                                                    path={`email`}
                                                    value={row?.email}
                                                    valuetype={"EMAIL"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    dataRequired={true}
                                                    required={true}
                                                    requiredMessage={'Email-Id is required'}
                                                    validationMessage={'Enter valid Email-Id'}
                                                    validate={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormPhIcon"></div>
                                                <TextInput
                                                    path={`phone`}
                                                    value={row?.phone}
                                                    valuetype={"PHONE"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Phone Number is required'}
                                                    validationMessage={'Enter valid Phone Number'}
                                                    dataRequired={true}
                                                    required={true}
                                                    validate={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">NMLS Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormHastagIcon"></div>
                                                <TextInput
                                                    path={`nmls`}
                                                    value={row?.nmls}
                                                    valuetype={"NUMBERS"}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'NMLS Number is required'}
                                                    validationMessage={'Enter valid NMLS Number'}
                                                    dataRequired={true}
                                                    required={true}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">State Licensing</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <DropDown
                                                    path={`stateLicencinsing`}
                                                    value={row?.stateLicencinsing}
                                                    onInputChildChanged={OnInputChanged}
                                                    items={Support.StateLicencingTypes()}
                                                    displayName={"text"}
                                                    displayValue={"value"}
                                                    errors={errors}
                                                    indexValue={1}
                                                    requiredMessage={"State Licensing is required"}
                                                />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">How you best describe yourself</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <DropDown
                                                    path={`describeYourSelf`}
                                                    value={row?.describeYourSelf}
                                                    onInputChildChanged={OnInputChanged}
                                                    items={Support.HowDoYouDescribeTypes()}
                                                    displayName={"text"}
                                                    displayValue={"value"}
                                                    errors={errors}
                                                    indexValue={1}
                                                    requiredMessage={"How you best describe yourself is required"}
                                                />
                                            </li>
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <textarea></textarea>
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input
                                                type="button"
                                                value="Apply Now"
                                                className="contactFormBtn"
                                                onClick={(e) => OnApplyNowClicked(e)}
                                            />
                                        </div>
                                        {error && <div className="errormessage">{error}</div>}

                                        {success && <div className="successmessage">Thank you for contacting us. We will get back to you ASAP.</div>}
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul >
                        </div >
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
