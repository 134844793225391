import { React } from "../../../../common";
import Container from "../../../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_generic">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Privacy Statement</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="Icol">
                                <div className="pgTxtBold_L">Siddhi Financials has created this privacy statement in order to demonstrate our firm commitment to privacy.</div>
                                <div className="pgTxtBold">What personal information is being collected?</div>
                                <div className="pgTxtNormal">Unless this web site asks for specific personal information in order to respond to requests for information or to register uses for particular services, only the following information will be collected when you use this site:</div>
                                <ul className="bulletPoints_un">
                                    <li className="bp_circleOutline">Your server address</li>
                                    <li className="bp_circleOutline">The date and time of your visit to the site</li>
                                    <li className="bp_circleOutline">The pages you accessed and the documents downloaded</li>
                                    <li className="bp_circleOutline">The previous site you visited; and</li>
                                    <li className="bp_circleOutline">The type of browser you are using.</li>
                                </ul>
                                <div className="pgTxtNormal">This information is collected for statistical purposes and to enable us to improve the navigation functions of our web site.</div>
                                <div className="pgTxtBold">Who is collecting personal information?</div>
                                <div className="pgTxtNormal">Where this site specifically asks for your personal information (for example to respond to requests for information or to register users for particular services), your personal information will only be collected by staff of Siddhi Financials Mortgage who have responsibility for responding to such requests or administrating such registrations.</div>
                                <div className="pgTxtBold">How is personal information used?</div>
                                <div className="pgTxtNormal">Personal information collected on this web site will only be used for the purposes stated at the time of collection. Your personal information will not be forwarded to any third party, added to a mailing list or used for any other purpose without your consent.</div>
                                <div className="pgTxtBold">Cookies</div>
                                <div className="pgTxtNormal">Cookies can be either “persistent” or “session” based. Persistent cookies are stored on your computer, contain an expiration date, and may be used to track your browsing behaviour upon return to the issuing web site. Session cookies are short-lived, are used only during a browsing session, and expire when you quit your browser. Upon closing your browser the session cookie set by this web site is destroyed and no personal information is maintained which might identify you should you visit our web site at a later date. Access to personal information collected on this site Unless required by law to do so Siddhi Financials Mortgage will only disclose personal information collected on this site to a third party if consent has been provided.</div>
                                <div className="pgTxtBold">Security of personal information collected on this site</div>
                                <div className="pgTxtNormal">This site does not provide facilities to guarantee the secure transmission of information across the Internet. Whilst reasonable efforts are used to provide security, users should be aware that there are inherent risks in the transmission of information across the Internet.</div>
                                <div className="pgTxtBold">Outside Links</div>
                                <div className="pgTxtNormal">These sites may on occasion provide links to other sites for your convenience or additional information. Siddhi Financials Mortgage is not responsible for any content or practices of these sites.</div>
                                <div className="pgTxtBold">Your Rights</div>
                                <div className="pgTxtNormal">You can request access to the personal information (if any) that we keep on record. If you believe any information that we keep on record is incorrect or out of date you may request that it be corrected. There is no fee for requesting access to your information, however, we may charge you the reasonable cost of processing your request.</div>
                                <div className="pgTxtBold">Further Information</div>
                                <div className="pgTxtNormal">For further information about this Privacy Statement contact info@siddfi.com.</div>
                            </div>

                        </div >

                    </div >
                </div >
            </Container >
        </>
    )
}

export default Component;
