import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_buy">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Buy your Second Home</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">Purchasing a second home is a significant accomplishment for most homeowners and a decision that’s filled with dreams for the future. As an experienced home owner, you already have a good understanding of the decisions ahead. Making it easy and convenient to help get you into your new second home is our goal at Siddhi Financials LLC.</div>
                                    <div className="pgTxtNormal">Pave the way to your second home purchase by reviewing some essentials:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">What loans are available for vacation home purchases?</li>
                                        <li className="bp_circleOutline">What if I’m purchasing out-of-state?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage prequalification to closing?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;