import API from "./api";
//import session from "../session";

const GetOtp = async (type, input) => {
    try {
        const res = await API.get(`common/${type}/${input}/otp`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const ValidateOtp = async (type, input, otp) => {
    try {
        const res = await API.get(`common/${type}/${input}/validateotp/${otp}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const Contact = async (input) => {
    try {
        const res = await API.post(`common/contact`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddEnquiry = async (input) => {
    try {
        const res = await API.post(`common/enquiry`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const QuickApply = async (input) => {
    try {
        const res = await API.post(`common/quickapply`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const CreditScore = async (input) => {
    try {
        const res = await API.post(`common/creditscore`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export {
    GetOtp, Contact, AddEnquiry, QuickApply, CreditScore, ValidateOtp
};
