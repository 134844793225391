import { React, Range } from "common";

const Component = () => {
    const [values, setValues] = React.useState([16000]);
    const [months, setMonths] = React.useState([8]);
    const [monthlyPay, setMonthlyPay] = React.useState('');
    const [totalPay, setTotalPay] = React.useState('');

    React.useEffect(() => {
        if (values && months) {
            let _valPerMonth = values / months;
            let _monthlyPay = parseInt(_valPerMonth + _valPerMonth * 0.15);
            let _totalPay = parseInt(values) + parseInt(_monthlyPay);
            setMonthlyPay(_monthlyPay);
            setTotalPay(_totalPay);
        }
    }, [values, months]);

    return (
        <>
            <div className="applyloancontainer">
                <div className="amountrange">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{`$1000`}</div>
                        <div>{`$40000`}</div>
                    </div>
                    <Range
                        step={1000}
                        min={1000}
                        max={40000}
                        values={values}
                        onChange={(values) => setValues(values)}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '6px',
                                    width: '100%',
                                    backgroundColor: '#ccc',
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '30px',
                                    width: '10px',
                                    backgroundColor: '#999',
                                }}
                            />
                        )}
                    />
                </div>
                <div className="monthsrange" style={{ marginTop: '50px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{`1 Month`}</div>
                        <div>{`12 Months`}</div>
                    </div>
                    <Range
                        step={1}
                        min={1}
                        max={12}
                        values={months}
                        onChange={(values) => setMonths(values)}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '6px',
                                    width: '100%',
                                    backgroundColor: '#ccc',
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '30px',
                                    width: '10px',
                                    backgroundColor: '#999',
                                }}
                            />
                        )}
                    />
                </div>
                <div className="monthlypay" style={{ marginTop: '30px' }}>
                    Pay Monthly: {`$${monthlyPay}`}
                </div>
                <div className="termsuse" style={{ marginTop: '30px' }}>
                    Terms Of Use: {months} Months
                </div>
                <div className="totalpay" style={{ marginTop: '30px' }}>
                    Total Pay Back: {`$${totalPay}`}
                </div>
            </div>
        </>
    );
};


export default Component;
