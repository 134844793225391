import React from "react";
import { Helper } from "helper";

const Component = (props) => {

    const { index, path, name, value, valuetype, maxLength, onBlurError, onInputClicked, onInputChildChanged,
        required, validate, requiredMessage, validationMessage,
        readOnly, currency, placeholder, errors, dataRequired, uppercase,
        inputtype } = props;

    const [dataError, setDataError] = React.useState(false);

    let tInputType = inputtype || "text";

    const OnInputClicked = (e) => {
        e.preventDefault();
        if (onInputClicked) {
            onInputClicked(e.target.name);
        }
    }

    const OnBlurChanged = async (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;
        setDataError(false);

        if (valuetype && valuetype === "EMAIL") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsEmailValid(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "PHONE") {
            let formatVal = Helper.ToUSAPhone(value);
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && Helper.IsNullValue(formatVal)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                OnTextInputChanged(e, formatVal);
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "NUMBERS") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "SSN") {
            let formatVal = Helper.ToSSNNumber(value);
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && Helper.IsNullValue(formatVal)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                OnTextInputChanged(e, formatVal);
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "DATE") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsValidateDate(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "DOB") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsValidateDate(value, "DOB")) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "ZIP") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value)) {
                global.Busy(true);
                await Helper.IsValidZipCode(value)
                    .then((res) => {
                        let tmp = res.data;
                        let data = { state: "", city: "", district: "" };
                        if (tmp.status === 100) {
                            tmp = tmp.data || { state: "", city: "", district: "" };
                            data = { state: tmp.state, city: tmp.city, district: tmp.district };
                        }
                        OnZipUpdated(data);
                    })
                    .catch(() => RaiseOnBlurError(name, validationMessage));
                return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "AADHAR") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsAadharValid(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "PAN") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsPanValid(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "VOTERID") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !Helper.IsNullValue(value) && !Helper.IsVoterIdValid(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        }
    };

    const RaiseOnBlurError = (name, val, clear, data) => {
        global.Busy(false);
        if (onBlurError) {
            if (!clear) {
                setDataError(true);
            } else {
                setDataError(false);
            }
            onBlurError(name, val, clear, data);
        }
    }

    const OnTextInputChanged = (e, formatVal) => {
        e.preventDefault();
        if (onInputChildChanged) {
            let value = formatVal ? formatVal : e.target.value;
            let _name = GetKeyName();
            if (uppercase && !Helper.IsNullValue(value)) value = value.toUpperCase();
            onInputChildChanged(_name, value, index);
        }
    };

    const OnZipUpdated = (value) => {
        global.Busy(false);
        if (onInputChildChanged) {
            let _name = GetKeyName();
            let _path = _name.substring(0, _name.lastIndexOf("_"));
            let obj = {};
            for (var key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) {
                    obj[`${_path}_${key}`] = value[key];
                }
            }
            onInputChildChanged(obj, null, index, 'ZIP');
        }
    };

    const GetKeyName = () => {
        let _name = `textinput`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    if (Helper.IsNullValue(currency)) {
        return (
            <input
                autoComplete={Helper.TimeStamp()}
                id={GetKeyName()}
                name={GetKeyName()}
                type={tInputType}
                value={Helper.ToString(value)}
                maxLength={maxLength && maxLength}
                placeholder={Helper.ToString(placeholder)}
                onChange={(e) => OnTextInputChanged(e)}
                style={{
                    borderColor: Helper.GetErrorBackColor(errors, GetKeyName()),
                }}
                onKeyPress={(e) => {
                    return valuetype && Helper.AcceptedChars(e, valuetype);
                }}
                onBlur={(e) => OnBlurChanged(e)}
                onFocus={(e) => OnInputClicked(e)}
                readOnly={readOnly ? true : undefined}
                data-req={dataRequired}
                data-error={dataError}
                data-req-msg={requiredMessage}
                data-val-msg={validationMessage}
            />
        );
    } else {
        return (
            <div className="input-icon">
                <input
                    autoComplete={"off"}
                    id={GetKeyName()}
                    name={GetKeyName()}
                    type="text"
                    value={Helper.ToString(value)}
                    maxLength={maxLength && maxLength}
                    placeholder={Helper.ToString(placeholder)}
                    onChange={(e) => OnTextInputChanged(e)}
                    style={{
                        borderColor: Helper.GetErrorBackColor(errors, GetKeyName()),
                        paddingLeft: 20,
                    }}
                    onKeyPress={(e) => {
                        return valuetype && Helper.AcceptedChars(e, valuetype);
                    }}
                    onBlur={(e) => OnBlurChanged(e)}
                    readOnly={readOnly ? true : undefined}
                    data-req={dataRequired}
                    data-req-msg={requiredMessage}
                    data-val-msg={validationMessage}
                />
                <i>{currency}</i>
            </div>
        );
    }
};

export default Component;
