import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refinance">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Consolidate Your Debt</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">A cash-out refinance can give you money in your pocket to help make home improvements, consolidate existing debt, buy a new car, pay college tuition or finance other goals. With this kind of refinancing, you will pay off your current mortgage loan and take out a new mortgage at a higher amount. You will need to have adequate equity in your home to make this possible.</div>
                                    <div className="pgTxtNormal"><b>Example:</b><br />The Smiths’ home is appraised at $175,000 and they have $108,000 and 25 years remaining on a 30-year fixed-rate mortgage. They want to get $25,000 cash out of their refinance to pay off their credit card debt and put a downpayment on a new car.</div>
                                    <div className="refin_6col_consolidate">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Mortgage Amount</td>
                                                    <td>Rate</td>
                                                    <td>Term</td>
                                                    <td>Monthly P&I Payment Cash Out</td>
                                                    <td>Cash Out</td>
                                                </tr>
                                                <tr>
                                                    <td>Current mortgage</td>
                                                    <td>$108,000</td>
                                                    <td>5.25%</td>
                                                    <td>25 years remaining on 30-year fixed</td>
                                                    <td>$673</td>
                                                    <td>N/A</td>
                                                </tr>
                                                <tr>
                                                    <td>Refinance/new mortgage</td>
                                                    <td>$132,000</td>
                                                    <td>4.75%</td>
                                                    <td>30-year fixed</td>
                                                    <td>$689</td>
                                                    <td>$25,000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtNormal">NOTE: You may also want to consider a Siddhi Financials Home Equity Loan , which feature lower closing costs than mortgages and allow you to get the cash you need from your home’s equity.</div>

                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;