import { React, Link } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_options">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Loan Options</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Non-Qualified Mortgage</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div id="bc_option_nonqm" style={{ display: "none" }}>
                            <div className="widthfix_10px">
                                <div className="bcTxt_blk">
                                    <div className="bcTxt"><Link to="/">Home</Link></div>
                                    <div className="bcTxt">Loan Options</div>
                                    <div className="bcTxt">Non-QM</div>
                                </div>
                            </div>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBold">What&#39;s A Non-Qualified Mortgage?</div>

                                    <div className="pgTxtNormal">A non-qualified mortgage (nonQM loan) doesn&#39;t conform to the consumer protection provisions of the Dodd-Frank Act. Applicants whose incomes vary from month to month or those with other unique circumstances may qualify for these types of mortgages.</div>
                                    <div className="pgTxtNormal">For example, if you have a DTI of more than 43%, a lender may not offer you a qualified mortgage. Or, if you have erratic income and don&#39;t meet the income verification requirements set out in Dodd-Frank and required of most lenders, you may not be offered a qualified mortgage.</div>
                                    <div className="pgTxtNormal">A lender may instead decide to offer you a nonqualified mortgage. If a lender offers you a nonqualified mortgage, it doesn&#39;t mean they aren&#39;t required to do any verification or assessment of your ability to repay the loan. It just means that you don&#39;t meet the specific criteria needed for a qualified mortgage.</div>
                                    <div className="pgTxtNormal">According to data from CoreLogic, the three main reasons borrowers seek out a nonqualified mortgage are:</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquareOutline">
                                            <li>Limited documentation</li>
                                            <li>DTI of greater than 43%</li>
                                            <li>Interest-only loans</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Interest rates on loans will vary from lender to lender, but you may find that a nonqualified mortgage will have a higher interest rate.</div>

                                    <div className="pgTxtBold">How Do Lenders Verify Income For Non-QM Loans?</div>

                                    <div className="pgTxtNormal">Though nonQM loans don&#39;t meet the standards required for QM loans, they aren&#39;t necessarily low-quality loans. CoreLogic data found that in 2018, nonQM borrowers had an average credit score of 760. QM borrowers had an average credit score of 754. The average loan-to-value ratio for nonQMs was 79%, compared to 80% for QM loans.</div>

                                    <div className="pgTxtNormal">But, nonQM borrowers do have, on average, higher DTI ratios than QM borrowers.</div>

                                    <div className="pgTxtNormal">NonQM loans offer flexibility for lenders to offer mortgages to people who don&#39;t fit the criteria of QM loans, but lenders still need to do the work of verifying the information provided.They need to verify and document anything that supports the borrower&#39;s ability to repay. That includes income sources. They may also want to verify assets or anything else that gives them assurance the borrow will be able to repay the loan.</div>

                                    <div className="pgTxtNormal">NonQM loans are not insured, guaranteed or backed by FHA, VA, Fannie Mae or Freddie Mac.</div>

                                    <div className="pgTxtBold">NonQM Products/Programs</div>

                                    <div className="pgTxtNormal">
                                        <ol className="bulletNumber">
                                            <li>Bank Statement &#8208; 12/24 Month</li>
                                            <li>1099 Income Loan</li>
                                            <li>Profit &amp; Loss</li>
                                            <li>Asset Utilization</li>
                                            <li>WVOE</li>
                                            <li>Investor Cash Flow/DSCR</li>
                                            <li>ITIN Mortgage Loan</li>
                                            <li>Non-Warrantable Condos</li>
                                            <li>Foreign National</li>
                                        </ol>
                                    </div>

                                    <div className="pgTxtBold">1. Bank Statement &#8208; 12/24 Month</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>Credit scores starting at 680</li>
                                            <li>Up to 80% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Bank Statement mortgage program is the perfect option for self-employed borrowers who need an alternative method to show the true cash flow of their business. Borrowers do not have to own 100% of the business. Self-employed borrowers represent an underserved market in the mortgage industry. Our Bank Statement program provides a loan solution to help underserved credit-worthy self-employed borrowers who otherwise would not qualify for a home loan.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Loans up to $3 million with a minimum of $150,000</li>
                                            <li>12 or 24 months business or personal bank statements</li>
                                            <li>Two years seasoning for foreclosure, short sale, bankruptcy or deed-in-lieu</li>
                                            <li>Rates are 30-year fixed</li>
                                            <li>Two years self-employed required</li>
                                            <li>Borrowers can own as little as 50% of the business for business bank statements and 25% for personal bank statements</li>
                                            <li>Purchase and cash-out or rate-term refinance</li>
                                            <li>Owner-occupied, second homes, and non-owner occupied</li>
                                            <li>1099 option available</li>
                                            <li>40 year fixed interest only available</li>
                                            <li>Most loans will be qualified on an expense factor of 50%.Companies with a lower expense factor will require a statement from a third party CPA or tax preparer. &#x2772;Some industries with traditionally higher expense factors will be underwritten with a 70% expense factor&#x2773;. </li>
                                            <li>Non-warrantable condos allowed</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">2. 1099 Income Loan</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>Credit scores starting at 680</li>
                                            <li>Up to 80% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Our 1099 income loan option is for underserved self-employed borrowers who are 1099 workers. Many freelancers, contractors, gig economy workers or other self-employed borrowers who file using W-9s cannot qualify for a mortgage under Agency guidelines.</div>

                                    <div className="pgTxtNormal">These underserved borrowers can use 1099 earning statements in lieu of tax returns to qualify for a mortgage. Our 1099 Income loan is an alternative loan solution that helps many self-employed 1099 earners achieve homeownership.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Loans up to $3 million, Minimum loan of $150,000</li>
                                            <li>No tax returns are required</li>
                                            <li>Most recent one or two years 1099 plus year to date earning statement allowed</li>
                                            <li>Year to date earnings are verified from earning statement, paystubs, or bank statements</li>
                                            <li>1099s must be from a single employer</li>
                                            <li>Borrower must be self-employed working for the same employer for two years</li>
                                            <li>Owner-occupied, second homes, and non-owner occupied</li>
                                            <li>Purchase and cash-out or rate-term refinance</li>
                                            <li>Two years seasoning for foreclosure, short sale, bankruptcy or deed-in-lieu</li>
                                            <li>Non-warrantable condos allowed</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">3. Profit &amp; Loss</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Minimum FICO 680, Maximum 80% LTV*, Maximum Loan Amount $3,000,000</li>
                                            <li>No 4506-C / No Tax Transcripts / No Tax Return</li>
                                            <li>1 Year CPA/CTEC/EA prepared P&amp;L statement</li>
                                            <li>Asset statements can supplement income</li>
                                            <li>Interest only available</li>
                                            <li>*LTV 80% is available for FICO 700+</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">4. Asset Utilization</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>Credit scores starting at 700</li>
                                            <li>Up to 75% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Asset Qualifier loan product is for borrower&#39;s to qualify using their liquid assets.We do not require employment, income or DTI to justify ability-to-repay.We qualify based on required assets that meet seasoning requirements.We have helped retirees, underserved self-employed, divorced with no income, and other borrowers with required seasoned assets to purchase or refinance.This easy to close loan is another solution helping borrowers with their home loan needs who could not under Agency guidelines.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Loans up to $3 million, Minimum loan of $250,000</li>
                                            <li>No employment, no income, no DTI</li>
                                            <li>Rates are 30-year fixed</li>
                                            <li>Five years seasoning foreclosure, short sale or bankruptcy</li>
                                            <li>Primary residence only</li>
                                            <li>Purchase and cash-out or rate-term refinance</li>
                                            <li>Interest only program available</li>
                                            <li>Non-warrantable condos allowed</li>
                                            <li>All assets must be sourced and seasoned for a minimum of six months</li>
                                            <li>Required assets: Loan amount, recurring monthly debt multiplied by 60 months, funds to close and six months reserves</li>
                                            <li>Borrowers must have at least $500,000 in post-closing assets</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">5. WVOE</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Max 80% LTV</li>
                                            <li>OO Only</li>
                                            <li>Min 600 FICO</li>
                                            <li>Minimum 24 month rent or MTG history</li>
                                            <li>No FTHB</li>
                                            <li>Property Types: SFR / Condo / Town home (No rural or units)</li>
                                            <li>Borrower cannot be employed by a family member</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">6. Investor Cash Flow/DSCR</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>Credit scores starting at 680</li>
                                            <li>Up to 75% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Investor Cash Flow mortgage program allows your clients to qualify based on rental analysis to determine property cash flow.No personal income required to qualify.This saves you from submitting complicated income statements and tax returns.Debt service coverage ratio or DSCR is a measurement of a property&#39;s expected cash flow to determine ability to repay a mortgage loan.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Loans up to $1.5 million, Minimum loan of $75,000</li>
                                            <li>Qualification based on property cash flow &#x2010; Minimum debt service coverage ratio 1.0</li>
                                            <li>No personal income or employment information required</li>
                                            <li>Short-term rentals and VRBOs ok / No condotels or hotels</li>
                                            <li>Properties can be in LLC&#39;s name</li>
                                            <li>No limit on total number of properties</li>
                                            <li>Borrower must own primary residence</li>
                                            <li>Purchase and cash-out or rate-term refinance</li>
                                            <li>40 year fixed interest only available</li>
                                            <li>Non-warrantable condos allowed</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">7. ITIN Mortgage Loan</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>Credit scores starting at 680</li>
                                            <li>Up to 75% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">Individual Tax Identification Number (ITIN) loans are for borrowers who do not have Social Security numbers. Borrowers with ITIN cards can qualify for a mortgage as long as they meet the eligibility requirements. This loan product is a full doc non-QM mortgage offering flexibility for individuals residing in the United States.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Loans up to $2.5 million, Minimum loan of $125,000</li>
                                            <li>One year seasoning for foreclosure, short sale or deed-in-lieu</li>
                                            <li>Two years seasoning for bankruptcy</li>
                                            <li>Primary home only</li>
                                            <li>Up to 50% DTI</li>
                                            <li>Non-warrantable condos allowed</li>
                                            <li>One unit only</li>
                                            <li>Purchase and cash-out or rate-term refinance</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                            <li>Not available in all states. Please speak with your account executive for more details</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">8. Non-Warrantable Condos</div>

                                    <div className="pgTxtNormal">What is a non-warrantable condo?</div>

                                    <div className="pgTxtNormal">A non-warrantable condo is also one that operates as a hotel or provides short-term rentals. Therefore, these types of condos are sometimes located in touristy areas like beach resorts and in college towns.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>All non-warrantable issues considered</li>
                                            <li>High investor concentration</li>
                                            <li>Large, single entity ownership</li>
                                            <li>High commercial percentage</li>
                                            <li>Small projects with no HOAs</li>
                                            <li>Condo conversions</li>
                                            <li>New developments</li>
                                            <li>Max 80% LTV (primary or second home)</li>
                                            <li>Max 75% LTV (investment properties)</li>
                                            <li>Maximum loan amounts up to $5,000,000 (LTV limits apply)</li>
                                            <li>Cash-out refinancing available with unlimited cash in hand</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtBold">9. Foreign National</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletArrow">
                                            <li>No Foreign or U.S. credit needed</li>
                                            <li>Up to 70% LTV</li>
                                        </ul>
                                    </div>

                                    <div className="pgTxtNormal">This mortgage product is for foreign nationals wanting to purchase or refinance a home in the United States.This is a DSCR program with a 1: 1 ratio on cash flow.This means that this loan is incredibly easy to do &#x2010; no income or U.S. credit required to qualify.</div>

                                    <div className="pgTxtNormal">
                                        <ul className="bulletSquare">
                                            <li>Minimum loan amount of $75,000</li>
                                            <li>Maximum loan amount of $1.5 million</li>
                                            <li>A DSCR program with a 1:1 ratio on cash flow</li>
                                            <li>Assets sourced and seasoned for 60 days - must be in a U.S. FDIC insured bank for a minimum of 30 days</li>
                                            <li>12 months reserves required and must be in a U.S. bank</li>
                                            <li>ACH auto-payment is required</li>
                                            <li>No sanction listed countries allowed and will not lend in Osceola County</li>
                                            <li>Cannot reside in the United States</li>
                                            <li>Must have an eligible Visa: B-1, B-2, H-2, H-3, I, J-1, J-2, O-2, P1, P2</li>
                                            <li>No gift funds allowed</li>
                                            <li>Loan amounts greater than or equal to $1,500,000, a borrower paid second appraisal must be obtained</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul>

                            <ul className="inPageBreadcrumbBlk" style={{ display: "none" }}>
                                <li className="inPageBreadcrumbBlk">
                                    <div className="pgBlkDesc">Non-QM Mortgage<span className="curveShape"></span></div>
                                    <div className="pgDescHeadTxt">Loan Options</div>
                                </li>
                                <li className="inPageBreadcrumbBlk">&nbsp;</li>
                            </ul>


                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Component;