const Component = () => {
    return (
        <ul className="uspContainer">
            <div className="widthfix_10px">
                <li className="usp_IVcol">
                    <div className="uspPointHead">99.9%</div>
                    <div className="uspPointAns">Success Rate</div>
                </li>
                <li className="usp_IVcol">
                    <div className="uspPointHead">8,000+</div>
                    <div className="uspPointAns">Happy Customers</div>
                </li>
                <li className="usp_IVcol">
                    <div className="uspPointHead">100+</div>
                    <div className="uspPointAns">Loan Officers</div>
                </li>
                <li className="usp_IVcol">
                    <div className="uspPointHead">8,500+</div>
                    <div className="uspPointAns">Loans Issued</div>
                </li>
            </div>
        </ul>

    )
}

export default Component;