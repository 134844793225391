import { React, Link, useLocation } from "../../common";

// Buy a Home
const mnu1 = [
    "/buyoverview",
    "/firsthome",
    "/nexthome",
    "/buildnewhome",
    "/secondhome",
    "/investment",
    "/foreclosed",
];
// Refinance
const mnu2 = [
    "/lowerpayment",
    "/reducerate",
    "/payoff",
    "/convert",
    "/consolidate",
    "/prepare",
    "/steps",
];
// Mortgage Tools
const mnu3 = [
    "/loanprocess",
    "/mortgagerates",
];
// Loan Options
const mnu4 = [
    "/thirty",
    "/fha",
    "/va",
    "/reverse",
    "/usda",
    "/jumbo",
    "/renovation",
];
// More
const mnu5 = [
    "/about",
    "/career",
    "/blogs",
    "/reviews",
    "/contact",
];

const LoggedOutHeader = (props) => {
    const { loc } = props;

    const IsActive = (mnu, arrow) => {
        if (arrow) return mnu.findIndex((x) => x === loc) > -1 ? "arrow_down_topnav active" : "arrow_down_topnav";
        return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
    };

    const OnAuthClicked = (e) => {
        e.preventDefault();
        window.fnSign(1);
    }

    return (
        <>
            <header className="headerSiddhi">
                <div className="widthfix">

                    <div className="logo">
                        <Link to="/"><div className="siddhiLogo logoPositionHome"></div></Link>
                    </div>

                    <div className="topRight_fixed_blk">
                        <div className="widthfix">
                            <div className="headerTopRight">

                                <ul className="header_phone_blk">
                                    <li className="header_ph_icon"></li>
                                    <li className="call_no_blk">
                                        <div className="callus">Call us at</div>
                                        <div className="phNo">(610) 977-3058</div>
                                    </li>
                                </ul>

                                <ul className="header_Account_blk">
                                    <li className="header_profile_img"></li>
                                    <li className="header_nameAcc_blk">
                                        <div className="headerProfileName">Hello, SignIn</div>
                                        <div className="headerAccount">
                                            <div className="accountnav" id="myAccountnav">
                                                <div className="dropdownAcc">
                                                    <button className="dropbtnAcc">Account <div className="arrow_down"></div></button>
                                                    <div className="dropdown-content">
                                                        <Link to="#" className="icon"><div className="loginIcon"></div>Login / Signup</Link>
                                                    </div>
                                                </div>
                                                <Link to="#" className="icon" onClick={() => window.accFunction()}></Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="getStarted_btnLabel">
                                    <Link to="#"><input type="button" className="getStarted_button" value="Get Started" /></Link>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="navBlock">
                    <div className="widthfix">
                        <div className="headerRightBlock">
                            <ul className="supportNavi">
                                <li className="supportNavi">
                                    <Link to="/becomebranch" title="Become a Branch">
                                        <div className="becomeBranchIcon"></div>
                                        <div className="supportNaviText">Become a Branch</div>
                                    </Link>
                                </li>
                                <li className="supportNavi">
                                    <Link to="/findlo" title="Find an Agent">
                                        <div className="findOfficerIcon"></div>
                                        <div className="supportNaviText">Find a Loan Officer</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="topnav">
                            <div className="dropdown">
                                <button className={IsActive(mnu1)}>
                                    Buy a Home <div className={IsActive(mnu1, true)}></div>
                                </button>
                                <div className="dropdown-content">
                                    <Link to="/buyoverview">Buy Overview</Link>
                                    <Link to="/firsthome">Buy your First Home</Link>
                                    <Link to="/nexthome">Buy your Next Home</Link>
                                    <Link to="/buildnewhome">Build New Home</Link>
                                    <Link to="/secondhome">Buy Your Second Home</Link>
                                    <Link to="/investment">Investment on Residential Property</Link>
                                    <Link to="/foreclosed">Buy a Foreclosed Home</Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <button className={IsActive(mnu2)}>
                                    Refinance <div className={IsActive(mnu2, true)}></div>
                                </button>
                                <div className="dropdown-content">
                                    <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                                    <Link to="/reducerate">Reduce Your Current Rate</Link>
                                    <Link to="/payoff">Pay Off Your Mortgage Sooner</Link>
                                    <Link to="/convert">Convert To A Fixed-Rate Loan</Link>
                                    <Link to="/consolidate">Consolidate Your Debt</Link>
                                    <Link to="/prepare">Prepare For Refinancing</Link>
                                    <Link to="/steps">Steps to Refinance</Link>
                                </div>
                            </div>

                            <div className="dropdown">
                                <button className={IsActive(mnu3)}>
                                    Mortgage Tools <div className={IsActive(mnu3, true)}></div>
                                </button>
                                <div className="dropdown-content">
                                    <Link to="/loanprocess">Loan Process</Link>
                                    <Link to="/mortgagerates">Mortgage Rates</Link>
                                    {/* <Link to="/mortgagecalculator">Mortgage Calculator</Link>
                                    <Link to="/refinancecalculator">Refinance Calculator</Link> */}
                                </div>
                            </div>

                            <div className="dropdown">
                                <button className={IsActive(mnu4)}>
                                    Loan Options <div className={IsActive(mnu4, true)}></div>
                                </button>
                                <div className="dropdown-content">
                                    <Link to="/thirty">30-year fixed rate mortgage</Link>
                                    <Link to="/fha">FHA Loans</Link>
                                    <Link to="/va">VA Loans</Link>
                                    <Link to="/reverse">Reverse Mortgages</Link>
                                    <Link to="/usda">USDA Loans</Link>
                                    <Link to="/jumbo">Jumbo Loans</Link>
                                    <Link to="/renovation">Renovation Loans</Link>
                                    <Link to="/nonqm">Non-QM</Link>
                                </div>
                            </div>

                            <div className="dropdown">
                                <button className={IsActive(mnu5)}>
                                    More <div className={IsActive(mnu5, true)}></div>
                                </button>
                                <div className="dropdown-content">
                                    <Link to="/about">About Us</Link>
                                    <Link to="/career">Careers</Link>
                                    <Link to="/blogs">Blogs</Link>
                                    <Link to="/reviews">Reviews</Link>
                                    <Link to="/contact">Contact Us</Link>
                                </div>
                            </div>
                            <Link to="#" className="icon" onClick={() => window.myFunction()}></Link>
                        </div>
                    </div>
                </div>
            </header>

            <header className="headerMini">
                {/* <div className="widthfix_10px"> */}
                <div className="headerTopRightMini">
                    <ul className="header_Account_blk_mini">
                        <li className="header_profile_img_mini"></li>
                        <li className="header_nameAcc_blk_mini">
                            <div className="headerProfileName_mini">Hello, Sign In</div>
                            <div className="headerAccount_mini">
                                <div className="accountnav_mini" id="myAccountnav">
                                    <div className="dropdownAcc_mini">
                                        <button className="dropbtnAcc_mini">Account <div className="arrow_down_mini"></div></button>
                                        <div className="dropdown-content_mini">
                                            <Link to="#" className="icon" onClick={(e) => OnAuthClicked(e)}><div className="loginIcon"></div>Login / Signup</Link>
                                        </div>
                                    </div>
                                    <Link to="#" className="icon"></Link>
                                    {/* onClick={() => window.accFunction()} */}
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="navBlockMini">
                    <div className="topnav" id="myTopnav">
                        <Link to="/"><div className="logoPosMini siddhiLogo"></div></Link>
                        <div className="dropdown">
                            <button className="dropbtn">Buy a Home <div className="arrow_down_topnav"></div></button>
                            <div className="dropdown-content">
                                <Link to="/buyoverview">Buy Overview</Link>
                                <Link to="/firsthome">Buy your First Home</Link>
                                <Link to="/nexthome">Buy your Next Home</Link>
                                <Link to="/buildnewhome">Build New Home</Link>
                                <Link to="/secondhome">Buy Your Second Home</Link>
                                <Link to="/investment">Investment on Residential Property</Link>
                                <Link to="/foreclosed">Buy a Foreclosed Home</Link>
                            </div>
                        </div>
                        <div className="dropdown">
                            <button className="dropbtn">Refinance <div className="arrow_down_topnav"></div></button>
                            <div className="dropdown-content">
                                <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                                <Link to="/reducerate">Reduce Your Current Rate</Link>
                                <Link to="/payoff">Pay Off Your Mortgage Sooner</Link>
                                <Link to="/convert">Convert To A Fixed-Rate Loan</Link>
                                <Link to="/consolidate">Consolidate Your Debt</Link>
                                <Link to="/prepare">Prepare For Refinancing</Link>
                                <Link to="/steps">Steps to Refinance</Link>
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className="dropbtn">Mortgage Tools <div className="arrow_down_topnav"></div></button>
                            <div className="dropdown-content">
                                <Link to="/loanprocess">Loan Process</Link>
                                <Link to="/mortgagerates">Mortgage Rates</Link>
                                {/* <Link to="/mortgagecalculator">Mortgage Calculator</Link>
                                    <Link to="/refinancecalculator">Refinance Calculator</Link> */}
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className="dropbtn">Loan Options <div className="arrow_down_topnav"></div></button>
                            <div className="dropdown-content">
                                <Link to="/thirty">30-year fixed rate mortgage</Link>
                                <Link to="/fha">FHA Loans</Link>
                                <Link to="/va">VA Loans</Link>
                                <Link to="/reverse">Reverse Mortgages</Link>
                                <Link to="/usda">USDA Loans</Link>
                                <Link to="/jumbo">Jumbo Loans</Link>
                                <Link to="/renovation">Renovation Loans</Link>
                                <Link to="/nonqm">Non-QM</Link>
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className="dropbtn">More <div className="line_topnav"></div></button>
                            <div className="dropdown-content">
                                <Link to="/about">About Us</Link>
                                <Link to="/career">Careers</Link>
                                <Link to="/blogs">Blogs</Link>
                                <Link to="/reviews">Reviews</Link>
                                <Link to="/contact">Contact Us</Link>
                            </div>
                        </div>
                        <Link to="#" className="icon" onClick={() => window.myFunction()}></Link>
                    </div>
                </div>
            </header>
        </>
    );
};

const LoggedInHeader = () => {
    //const [fullName, setFullName] = React.useState("Hello, Sign In");
    //const NavigateTo = useNavigate();

    // const changePassword = async (e) => {
    //     e.preventDefault();
    //     document.getElementById("changepswd_pop").style.display = "block";
    // };

    /* React.useEffect(() => {
        const fn = () => {
            let user = sessionStorage.getItem("user");
            setFullName(user);
        };
        fn();
    }, []); */

    /* const OnSignOutClicked = async (e) => {
        e.preventDefault();
        sessionStorage.removeItem("user");
        NavigateTo("/");
    }; */
};

const Component = (props) => {

    const [loggedIn, setLoggedIn] = React.useState(null);
    //const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const fn = () => {
            let user = sessionStorage.getItem("user");
            if (user) setLoggedIn(user);
        };
        fn();
    }, []);

    const loc = useLocation().pathname;

    const OnAuthClicked = () => {
        //setOpen(true);
        window.fnSign(1);
    }

    return (
        <>
            {loggedIn ? (
                <LoggedInHeader loc={loc} {...props} />
            ) : (
                <LoggedOutHeader onAuthClicked={OnAuthClicked} loc={loc} {...props} />
            )}
        </>
    );
};

export default Component;
